import React from 'react'
import { RichText } from 'prismic-reactjs'
import Pagination from '../02 Molecules/pagination'

function HeroTemplate({ props }) {
  if(!props.children.props.home) return null;
  let doc;
  let next;
  let prev;
  let first;
  let last;
  props.next ? next = props.next : next = null;
  props.prev ? prev = props.prev : prev = null;
  props.first ? first = props.first : first = 'index';
  props.last ? last = props.last : last = 'index';

  props.children.props.home.node ?
  doc = props.children.props.home.node :
  doc = props.children.props.home;

  return (
      <section className="hero hero--inspiration">
          <div className="hero__splash">
            <div className="hero__title">
                <span className="hero__numeral">{doc.numeral}</span>
                <h1>{RichText.asText(doc.title)}</h1>
                <Pagination first={first} last={last} prev={prev} next={next} current={doc} />
            </div>
          </div>
      </section>
  )
}
export default HeroTemplate
