import React from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'
import { Helmet } from 'react-helmet'
import Header from '../03 Organisms/header'
import Footer from '../03 Organisms/footer'
import Hero from '../03 Organisms/hero'
import HeroInspiration from '../03 Organisms/heroInspiration'
import CookieConsent from 'react-cookie-consent';

import '../../stylesheets/main.scss'

class Layout extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      menuOpen: false
    }
    this.handleMenuOpen = this.handleMenuOpen.bind(this)
    this.handleClickMenuItem = this.handleClickMenuItem.bind(this)
  }

  handleMenuOpen() {
    this.setState({ menuOpen: !this.state.menuOpen })
  }

  handleClickMenuItem() {
    this.setState({ menuOpen: false })
  }

  render() {
    return(
      <React.Fragment>
        <Helmet>
          <meta charSet="utf-8" />
          <meta name="description" content={this.props.data.node.meta_description ? this.props.data.node.meta_description[0].text : this.props.data.node.title[0].text} />
          <title>{this.props.data.node.meta_title ? this.props.data.node.meta_title[0].text : this.props.data.node.title[0].text + " | TotalTrips.com"}</title>
        </Helmet>
        <Header data={this.data} />
        {/* {this.props.data.node._meta.type === 'contact_form' ? 
          <Hero log={console.log("form")} data={this.props.data} />
        : ""} */}
        {this.props.data.node._meta.type === 'inspiration' || !'404' || this.props.data.node._meta.type === 'inspiration' ?
          <HeroInspiration props={this.props} />
        :
          <Hero data={this.props.children} />
        }
        <main className={this.props.data ? (this.props.data.node._meta.uid + " " + (this.props.data.node._meta.type === "landing_page" ? "landing-page" : this.props.data.node._meta.type + "-page")) : "page"}>
          {this.props.children}
        </main>
        <Footer />
        <CookieConsent
          enableDeclineButton
          flipButtons
          style={{ 
            background: "#FF6050",
            color: "white",
            fontSize: ".8rem"
          }}
          buttonStyle={{
            fontWeight: "bold",
            fontFamily: "'quicksand', 'helvetica', 'arial', sans-serif",
            color: "black",
            background: "white"
           }}          
          declineButtonStyle={{
            fontWeight: "bold",
            fontFamily: "'quicksand', 'helvetica', 'arial', sans-serif",
            color: "white",
            background: "none",
            textDecoration: "underline"
           }}          
          location="bottom"
          buttonText="Accept"
          declineButtonText="Decline"
          cookieName="gatsby-gdpr-google-analytics">
            <p>This website stores cookies on your computer. These cookies are used to collect information about how you interact with this website and allow us to remember you.</p>
            <p>We use this information in order to improve and customize your browsing experience and for analytics and metrics about our visitors on this website.</p>
            <p>If you decline, your information won’t be tracked when you visit this website. A single cookie will be used in your browser to remember your preference not to be tracked.</p>
            <Link to="/cookie-policy/">Please read our cookie policy for further information.</Link>
        </CookieConsent>        
      </React.Fragment>
    );
  }
}

export default props => (
  <StaticQuery
    query={graphql`
    query MainQuery {
      prismic {
        allNavigations {
          edges {
            node {
              primary_navigation_links {
                link {
                  ... on PRISMIC_Landing_page {
                    _linkType
                    _meta {
                      type
                      uid
                    }
                    body {
                      ... on PRISMIC_Landing_pageBodyInspiration_links {
                        type
                        label
                        primary {
                          link {
                            ... on PRISMIC_Landing_page {
                              title
                              introduction
                              _meta {
                                uid
                                type
                              }
                            }
                          }
                        }                        
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    `}
    render={data => <Layout data={data} {...props}/>}
  />
)