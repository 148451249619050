import React from 'react'
import { Link } from "gatsby"
import { RichText } from 'prismic-reactjs'
import { linkResolver } from '../06 Utilities/linkResolver'

const Pagination = ({current, next, prev}) => {

    return (
        <div className="pagination">
            {prev ? 
            <Link to={linkResolver(prev)} aria-label="Previous Post">
                <span className="cta--previous">Prev<span className="dest">: {prev.numeral.length < 2 ? prev.numeral : ''} {RichText.asText(prev.title)}</span></span> 
            </Link>
            : 
            <span></span>
            }
            {current ? 
            <Link className="back" to={linkResolver(current.destination)} aria-label="pious Post">
                <span className="cta--back">Back to {RichText.asText(current.destination.title)}</span> 
            </Link>
            :
            ""}
            {next ? 
            <Link to={linkResolver(next)} aria-label="Next Post">
                <span className="cta">Next<span className="dest">: {next.numeral.length < 2 ? next.numeral : ''} {RichText.asText(next.title)}</span></span> 
            </Link>
            :
            <span></span>
            }
        </div>
    )
};

export default Pagination;