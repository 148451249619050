import React from 'react'
import { RichText } from 'prismic-reactjs'
import BackgroundImage from 'gatsby-background-image'

function HeroTemplate({data}) {
  if(!data.props.home) return null;
  let doc;
  data.props.home.node ?
  doc = data.props.home.node :
  doc = data.props.home;
  let type = doc._meta.type;
  let page = doc._meta.uid;
  
  return (
      <>
      {type !== 'destination' ? 
      <section className="hero">
          <BackgroundImage fluid={doc.hero_imageSharp.childImageSharp.fluid} className="hero__splash">
            <div className="hero__title">
            {doc ? 
              <h1 className={page}>{RichText.asText(doc.title)}</h1>
              :
              <h1></h1>
            }
            </div>
          </BackgroundImage>
          {doc.introduction ? doc.introduction[0].text ? 
          <div className="hero__intro">
            <div className="wrapper">
              {doc ? 
              RichText.render(doc.introduction)
              :
              <p></p>
              }
            </div>
            <div className="divider-wrap"><span className="divider"></span></div>
          </div>
          : "" : ""}
      </section>
      :
      <section className="hero hero--destination">
      <BackgroundImage fluid={doc.hero_imageSharp.childImageSharp.fluid} className="hero__splash hero__splash--destination">
      </BackgroundImage>
      <div className="hero__intro">
        <div className="wrapper">
          {doc ? 
          <>
          <h1 className={page}>{RichText.asText(doc.title)}</h1>
          {RichText.render(doc.introduction)}
          </>
          :
          <p>There doesn't seem to be anything here...</p>
          }
        </div>
        <div className="divider-wrap"><span className="divider"></span></div>
      </div>
  </section>
  }
  </>
  )
}
export default HeroTemplate
